import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  GetLaboratoryTestDocument,
  GetLaboratoryTestQuery,
  GetLaboratoryTestQueryVariables,
  GetTestLaboratoriesDocument,
  GetTestLaboratoriesQuery,
  GetTestLaboratoriesQueryVariables,
  useGetLaboratoriesQuery,
  useInsertLaboratoryTestMutation,
  useUpdateLaboratoryTestMutation,
} from '../generated/urql.administrator'
import { Controller, useForm } from 'react-hook-form'
import { useClient } from 'urql'

export type TestLaboratoryModalType = {
  show: (args: { id?: number; testId: number }) => void
}

type FormTestLaboratory = {
  laboratoryId: number
  price: number
  externalId?: string
}

const TestLaboratoryModal = forwardRef<
  TestLaboratoryModalType,
  {
    onChange: () => void
  }
>(({ onChange }, ref) => {
  const [show, setShow] = useState(false)
  const [id, setId] = useState<number>()
  const [testId, setTestId] = useState<number>()
  const client = useClient()
  const [, setGetLaboratoryTestFetching] = useState(false)
  const [{ fetching: updateLaboratoryTestFetching }, updateLaboratoryTest] =
    useUpdateLaboratoryTestMutation()
  const [{ fetching: insertLaboratoryTestFetching }, insertLaboratoryTest] =
    useInsertLaboratoryTestMutation()
  const [{ data: laboratoriesData, fetching: laboratoriesFetching }] =
    useGetLaboratoriesQuery({ variables: { limit: 0xfffff } })
  const [testLaboratories, setTestLaboratories] = useState<
    GetTestLaboratoriesQuery['laboratory_test']
  >([])
  const [generalError, setGeneralError] = useState<string>()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<FormTestLaboratory>({
    defaultValues: {
      laboratoryId: undefined,
      price: 0,
      externalId: undefined,
    },
  })
  useImperativeHandle(ref, () => ({
    show: ({ id, testId }) => {
      setId(id)
      setTestId(testId)
      reset({
        laboratoryId: undefined,
        price: 0,
        externalId: undefined,
      })
      setShow(true)
    },
  }))

  useEffect(() => {
    if (!testId) return

    client
      .query<GetTestLaboratoriesQuery, GetTestLaboratoriesQueryVariables>(
        GetTestLaboratoriesDocument,
        {
          testId,
          limit: 0xffff,
        }
      )
      .toPromise()
      .then(({ data }) => {
        if (data?.laboratory_test) {
          setTestLaboratories(data.laboratory_test)
        }
      })
      .finally(() => setGetLaboratoryTestFetching(false))
  }, [testId])

  useEffect(() => {
    if (id) {
      setGetLaboratoryTestFetching(true)
      client
        .query<GetLaboratoryTestQuery, GetLaboratoryTestQueryVariables>(
          GetLaboratoryTestDocument,
          {
            id,
          }
        )
        .toPromise()
        .then(({ data }) => {
          if (data?.laboratory_test_by_pk) {
            reset({
              laboratoryId: data.laboratory_test_by_pk.laboratoryId,
              price: data.laboratory_test_by_pk.price,
              externalId: data.laboratory_test_by_pk.externalId || undefined,
            })
          }
        })
        .finally(() => setGetLaboratoryTestFetching(false))
    }
  }, [id, client, reset])

  async function doSaveTestLaboratory(testLaboratory: FormTestLaboratory) {
    setGeneralError(undefined)

    if (!testId) {
      setGeneralError('Missing testId')
      return
    }

    const { error } = id
      ? await updateLaboratoryTest({
          ...testLaboratory,
          testId,
          id,
        })
      : await insertLaboratoryTest({
          ...testLaboratory,
          testId,
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setShow(false)
    setId(undefined)
    setTestId(undefined)
    onChange()
  }

  const availableLaboratories = useMemo(() => {
    return (laboratoriesData?.laboratory || []).filter(
      ({ id }) =>
        id === watch('laboratoryId') ||
        !testLaboratories.find(
          (laboratoryTest) => laboratoryTest.laboratoryId === id
        )
    )
  }, [testLaboratories, laboratoriesData, watch])

  return (
    <Modal
      show={show}
      onHide={() => {
        setId(undefined)
        setTestId(undefined)
        setShow(false)
      }}
      size="lg"
    >
      <Form onSubmit={handleSubmit(doSaveTestLaboratory)}>
        <Modal.Header closeButton>
          <Modal.Title>Badanie - laboratorium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generalError && <Alert variant="danger">{generalError}</Alert>}

          <Controller
            name="laboratoryId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FloatingLabel label="Badanie" className="mb-3">
                <Form.Select
                  disabled={
                    !availableLaboratories.length || laboratoriesFetching
                  }
                  value={String(value)}
                  onChange={onChange}
                >
                  <option>Proszę wybrać laboratorium</option>
                  {availableLaboratories.map((laboratory) => (
                    <option value={laboratory.id} key={laboratory.id}>
                      {laboratory.shortName || laboratory.name}
                    </option>
                  ))}
                </Form.Select>
                {errors.laboratoryId && (
                  <Form.Control.Feedback>
                    {errors.laboratoryId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}
          />

          <FloatingLabel label="Cena" className="mb-3">
            <Form.Control
              isInvalid={!!errors.price}
              type="text"
              placeholder="Cena"
              {...register('price', {
                required: 'Proszę podać cenę badania',
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: 'Nieprawidłowa cena',
                },
              })}
            />
            {errors.price && (
              <Form.Control.Feedback>
                {errors.price.message}
              </Form.Control.Feedback>
            )}{' '}
          </FloatingLabel>

          {laboratoriesData?.laboratory.find(
            ({ id }) => String(watch('laboratoryId')) === String(id)
          )?.useHL7 && (
            <FloatingLabel label="Identyfikator zewnętrzny" className="mb-3">
              <Form.Control
                isInvalid={!!errors.externalId}
                type="text"
                placeholder="Identyfikator zewnętrzny"
                {...register('externalId', {
                  required: 'Proszę podać identyfikator zewnętrzny',
                })}
              />
              {errors.externalId && (
                <Form.Control.Feedback>
                  {errors.externalId.message}
                </Form.Control.Feedback>
              )}{' '}
            </FloatingLabel>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            disabled={
              updateLaboratoryTestFetching || insertLaboratoryTestFetching
            }
          >
            Zapisz
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
})

export default TestLaboratoryModal
