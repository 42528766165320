import Meta from '../../components/Meta'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  GetLaboratoryTestsDocument,
  GetLaboratoryTestsQuery,
  GetLaboratoryTestsQueryVariables,
  useDeleteLaboratoryTestMutation,
} from '../../generated/urql.administrator'
import { useClient } from 'urql'
import { Button, ButtonGroup } from 'react-bootstrap'
import { BagPlus, PencilSquare, Trash } from 'react-bootstrap-icons'
import { InfiniteTable } from '../../components/InfiniteTable'
import LaboratoryTestModal, {
  LaboratoryTestModalType,
} from '../../components/LaboratoryTestModal'

const BatchSize = 20

const LaboratoryTests: FC<{ id: number; useHL7: boolean }> = ({
  id,
  useHL7,
}) => {
  // page content
  const pageTitle = 'Badania laboratorium'
  const [laboratoryTests, setLaboratoryTests] = useState<
    GetLaboratoryTestsQuery['laboratory_test']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const data = useMemo(() => laboratoryTests, [laboratoryTests])
  const client = useClient()
  const laboratoryTestModalRef = useRef<LaboratoryTestModalType>(null)
  const [, deleteLaboratoryTest] = useDeleteLaboratoryTestMutation()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetLaboratoryTestsQuery, GetLaboratoryTestsQueryVariables>(
          GetLaboratoryTestsDocument,
          { laboratoryId: id, offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setLaboratoryTests(
          refetch
            ? data.laboratory_test
            : laboratoryTests.concat(data.laboratory_test)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.laboratory_test_aggregate.aggregate?.count || 0)
      }
    },
    [client, laboratoryTests, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() =>
              laboratoryTestModalRef.current?.show({ laboratoryId: id, useHL7 })
            }
          >
            <BagPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetLaboratoryTestsQuery['laboratory_test'][number]>
        data={data}
        columns={[
          { Header: 'Id', accessor: 'id' },
          { Header: 'Nazwa', accessor: ({ test }) => test.name },
          {
            Header: 'Cena',
            accessor: ({ price }) => (
              <span className="text-nowrap">{price.toFixed(2)} zł</span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() =>
                      laboratoryTestModalRef.current?.show({
                        id: rowData.id,
                        laboratoryId: id,
                        useHL7,
                      })
                    }
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={async () => {
                      if (
                        window.confirm('Czy napewno chcesz usunąć badanie?')
                      ) {
                        await deleteLaboratoryTest({ id: rowData.id })
                        await loadMoreRows({ refetch: true })
                      }
                    }}
                  >
                    <Trash size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
      <LaboratoryTestModal
        ref={laboratoryTestModalRef}
        onChange={() => loadMoreRows({ refetch: true })}
      />
    </div>
  )
}

export default LaboratoryTests
